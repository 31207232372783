import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Map,
  LayersControl,
  ZoomControl,
  Marker,
  Tooltip,
  Popup,
  Polygon,
  Polyline,
  TileLayer,
  GeoJSON,
} from 'react-leaflet'
import { GoogleLayer } from 'react-leaflet-google'
import hash from 'object-hash'
import L from 'leaflet'
import { createPolygonFromBounds } from '../../helpers'
import NotificationMessage from '../NotificationMessage'
import CustomControls from './CustomControls'
import AnnotationTooltip from './AnnotationTooltip'
import SearchBar from '../SearchBar'
import { API_ROOT, PDF_PATH } from '../../config'
import { Products, Spexigon } from '../../api'
import './styles.css'
import pathify, { ellipse, orbitQualify, getMapCoverage } from 'spexi-pathify'
import { getCamera } from '../../helpers'
import { rhumbDistance } from '@turf/turf'
import { Link } from 'react-router-dom'
import { debounce, defer } from 'lodash'
import UserCard from '../UserCard'
import Contours from './Contours'
import '@bagage/leaflet.vectorgrid'
import { cellToLatLng } from 'h3-js'
import { latLngToH3GeoJson } from '../../util'

import markerIcon from './marker.png'
// import previewUploadedMarkerIcon from './preview-uploaded-marker.png'
import unqualifiedMarkerIcon from './unqualifiedMarker.png'
import projectIcon from './project-marker.png'
import userIcon from './user-marker.png'
// import { alertTypes } from '../../util/constants'

import startIcon from './start-marker.png'
import endIcon from './end-marker.png'
import panoIcon from '../AddFlight/pano-marker.png'

import geoLocationImage from './geolocation.png'
import Modal from '../Modal'

const gjv = require('geojson-validation')

const { BaseLayer } = LayersControl
const key = 'AIzaSyBE8iWMY6ucUjg54gBf5V--mOhy9O15AIM'
const terrain = 'TERRAIN'
const road = 'ROADMAP'
const satellite = 'SATELLITE'

const geoLocationIcon = new L.Icon({
  iconUrl: geoLocationImage,
  iconSize: [44, 44],
  iconAnchor: [22, 22],
  zindex: -1,
})

const markerImage = new L.Icon({
  iconUrl: markerIcon,
  iconSize: [37, 37],
  iconAnchor: [19, 19],
})

const unqualifiedMarkerImage = new L.Icon({
  iconUrl: unqualifiedMarkerIcon,
  iconSize: [37, 37],
  iconAnchor: [19, 19],
  zindex: -1,
})

const projectMarker = new L.Icon({
  iconUrl: projectIcon,
  iconSize: [40, 60],
  iconAnchor: [20, 60],
})

const userMarker = new L.Icon({
  iconUrl: userIcon,
  iconSize: [40, 60],
  iconAnchor: [20, 60],
})

const startMarker = new L.Icon({
  iconUrl: startIcon,
  iconSize: [16, 16],
  iconAnchor: [8, 8],
  zindex: 0,
})

const endMarker = new L.Icon({
  iconUrl: endIcon,
  iconSize: [16, 16],
  iconAnchor: [8, 8],
  zindex: 0,
})

const panoMarker = new L.Icon({
  iconUrl: panoIcon,
  iconSize: [37, 37],
  iconAnchor: [19, 19],
})

class MainMap extends Component {
  _mapEl = null
  _editableFG = null
  static propTypes = {
    match: PropTypes.object.isRequired,
    projects: PropTypes.array.isRequired,
    setViewportCentre: PropTypes.func.isRequired,
    getGeoLocation: PropTypes.func.isRequired,
    // setActiveFeatureGroup: PropTypes.func.isRequired,
    setRequestInProgress: PropTypes.func.isRequired,
    setReviseSearchBounds: PropTypes.func.isRequired,
    setLeafletMapElement: PropTypes.func.isRequired,
    setMapBounds: PropTypes.func.isRequired,
    setProjectsMapCenter: PropTypes.func.isRequired,
    projectsBoundsFetchData: PropTypes.func.isRequired,
    fetchNextProjectPage: PropTypes.func.isRequired,
    projectsMyFetchData: PropTypes.func.isRequired,
    getDroneList: PropTypes.func,
    userGeoLocation: PropTypes.array.isRequired,
    reviseSearchBounds: PropTypes.bool,
    openSingleProject: PropTypes.bool,
    mapZoomLevel: PropTypes.number,
    isEdittingFlightPlan: PropTypes.bool,
    previewMarkers: PropTypes.array,
    previewUploadedMarkers: PropTypes.array,
    flightPlans: PropTypes.array,
    profileCoordinate: PropTypes.array,
  }

  constructor(props) {
    super(props)
    this.state = {
      firstLoad: true,
      searchBar: false,
      userControls: false,
      isSpexigonSelectModalOpen: false,
    }
    this.contours = null
    this.vectorLayers = {}
    this.vectorLayerGroup = null
  }

  componentDidMount() {
    // delay this a bit so there is slightly less happening all at once in the UI
    setTimeout(() => {
      this.props.getGeoLocation()
    }, 3000)

    this.props.getDroneList()
    this.vectorLayerGroup = L.featureGroup()

    this.vectorLayerGroup
      .on('tooltipopen', function (e) {
        if (e.layer.properties) {
          const feature = e.layer
          // group the non-geospatial info first
          let tooltipText = '<div class="customTooltip">'
          Object.keys(feature.properties).forEach((key, index) => {
            if (feature.properties.hasOwnProperty(key)) {
              if (
                ![
                  'projection',
                  'northing/latitude',
                  'easting/longitude',
                ].includes(key)
              ) {
                if (key === 'name') {
                  tooltipText =
                    tooltipText +
                    `<span><strong><div class="title">${feature.properties[key]}</div></strong></span><br>`
                }
                if (key === 'description') {
                  tooltipText = tooltipText + `${feature.properties[key]}<br>`
                }
                if (key === 'Gauge_in') {
                  tooltipText =
                    tooltipText + `Gauge_in: ${feature.properties[key]}<br>`
                }
                if (key === 'MCODist') {
                  tooltipText =
                    tooltipText + `MCODist: ${feature.properties[key]}<br>`
                }
                if (key === 'Warping') {
                  tooltipText =
                    tooltipText + `Warping: ${feature.properties[key]}<br>`
                }
                if (key === 'Position') {
                  tooltipText =
                    tooltipText + `Position: ${feature.properties[key]}<br>`
                }
                if (key === 'CrossL_in') {
                  tooltipText =
                    tooltipText + `CrossL_in: ${feature.properties[key]}<br>`
                }
              }
            }
          })

          let geoTooltipText = '<div class="geo-data">'
          Object.keys(feature.properties).forEach((key, index) => {
            if (feature.properties.hasOwnProperty(key)) {
              if (
                [
                  'projection',
                  'northing/latitude',
                  'easting/longitude',
                ].includes(key)
              ) {
                geoTooltipText =
                  geoTooltipText +
                  `<span>${key} : ${feature.properties[key]} </span><br>`
              }
            }
          })

          geoTooltipText = geoTooltipText + '</div>'
          tooltipText = tooltipText + geoTooltipText + '</div>'

          // Populate tooltip.
          this.setTooltipContent(tooltipText)
        }
      })
      .bindTooltip('', { sticky: true, direction: 'top' })

    this.vectorLayerGroup.addTo(this._mapRef.leafletElement)
  }

  componentDidUpdate(prevProps) {
    if (this.props.mapLayers.contours !== prevProps.mapLayers.contours) {
      this.initMapContoursLayer()
    }
    // Force rerender if the color of a layer changed
    for (let index = 0; index < this.props.productMapLayers.length; index++) {
      const layer = this.props.productMapLayers[index]
      if (
        prevProps.productMapLayers[index] &&
        prevProps.productMapLayers[index].color !== layer.color
      ) {
        this.vectorLayers[layer.name].remove()
        delete this.vectorLayers[layer.name]
      }
    }
    this.initProductMapLayers(this.props.productMapLayers)
    // only render this for role 'spexigon-user'
    if (
      !prevProps.user &&
      this.props.user &&
      this.props.user.roles.filter((role) => role.name === 'spexigon-user')
        .length > 0
    ) {
      this.initSpexigonLayer()
    }
  }

  componentWillUnmount() {
    if (this.contours) {
      this.removeContoursMapLayer()
    }
    if (this.vectorLayers) {
      Object.keys(this.vectorLayers).forEach((key) => {
        this.vectorLayers[key].remove()
      })
    }
  }

  onViewportChanged = debounce(
    (event) => {
      if (event.center && event.center.length > 0) {
        this.props.setViewportCentre(event.center)
      }

      if (this.state.firstLoad) {
        this.setState({ firstLoad: false })
      } else {
        navigator.geolocation.clearWatch(window.geoWatch)
        this.props.setRequestInProgress(false, 'LOCATION')
        this.props.setReviseSearchBounds(true)
      }
    },
    1000,
    { leading: false, trailing: true }
  )

  getBoundedProjects = () => {
    let bbox = createPolygonFromBounds(this._mapEl.getBounds())
    let boundsPolygon = L.polygon(bbox).toGeoJSON(8)

    if (boundsPolygon) {
      let bounds = JSON.stringify(boundsPolygon)
      this.props.setMapBounds(bounds)
      this.props.projectsBoundsFetchData(bounds)
    }
  }

  getLocationHandler = (event) => {
    event.preventDefault()
    this.props.getGeoLocation(true)
  }

  fetchProjects = (event) => {
    if (event) {
      event.preventDefault()
    }
    // TODO: make this be the actual thing that does the fetch
  }

  /**
   * Save leaflet map element to do leaflet things outside of
   * what React Leaflet alone can do.
   */
  mapRef = (mapRef) => {
    this._mapEl = mapRef.target
    this.props.setLeafletMapElement(this._mapEl)
  }

  /**
   * Search revised area after map in dragged
   */
  searchArea = (e) => {
    e.target.classList.add('clicked')
    this.getBoundedProjects()
  }

  searchBarToggle = (e) => {
    e.preventDefault()
    let toggle = this.state.searchBar ? false : true
    this.setState({ searchBar: toggle })
  }

  /**
   * Contours Map layer
   */
  initMapContoursLayer = async () => {
    // hacky way to make sure we delete the contours from the map when we navigate away
    const showContours =
      (this.props.match.path === '/project/:id/review/:pid' ||
        this.props.match.path === '/project/:id/review/:pid/add-annotation' ||
        this.props.match.path === '/project/:id/review/:pid/:aid/edit') &&
      this.props.openSingleProject &&
      (this.props.product.type === 'map' ||
        this.props.product.type === 'map+3d') &&
      this.props.mapLayers.contours &&
      !this.contours
    if (showContours) {
      this.getMapContoursLayerData()
    } else if (this.contours) {
      this.removeContoursMapLayer()
    }
  }

  getMapContoursLayerData = () => {
    // data previously fetched
    if (this.props.contoursMapLayer.data) {
      console.log('Loading contours map file ...')
      this.props.setContoursMapLayerData({
        loading: true,
        message: 'Loading layer ...',
      })
      // allow loading state to be set
      defer(() => {
        this.initContoursLayerOnMap(this.props.contoursMapLayer.data)
        this.props.setContoursMapLayerData({ loading: false })
      })
      return
    }

    console.log('Fetching contours map file ...')
    this.props.setContoursMapLayerData({
      loading: true,
      message: 'Fetching layer data ...',
    })

    let url = `${this.props.product.bucket.base_url}/${this.props.product.id}/contour.geojson`
    fetch(url)
      .then((response) => {
        if (response?.ok) {
          response.json().then((json) => {
            if (!response.ok) {
              return Promise.reject(json)
            }

            this.initContoursLayerOnMap(json)
            this.props.setContoursMapLayerData({
              loading: false,
              error: false,
              data: json,
              productId: this.props.product.id,
            })
          })
        }
      })
      .catch((err) => {
        this.props.setContoursMapLayerData({
          error: true,
          loading: false,
          message: err,
        })
      })
  }

  initContoursLayerOnMap = (layer) => {
    const gridLayerOpts = { zIndex: 210 }
    const vectorTileOpts = { maxZoom: 24 }
    this.contours = new Contours(layer, vectorTileOpts, gridLayerOpts)
    this.contours.addTo(this._mapRef.leafletElement)
    console.log('Contours Map loaded ...')
  }

  removeContoursMapLayer = () => {
    this.contours.remove()
    this.contours = null
  }

  initSpexigonLayer = async () => {
    console.log('loading spexigons')
    const request = await Spexigon.getAll()
    if (request.success) {
      this.props.setSpexigonData(request.data)
    }
  }

  /**
   *  Render flight plans when viewing an existing project or creating a new project
   */
  renderFlightPlans = (flightPlans) => {
    return flightPlans.map((flightPlan) => {
      let coordinates = flightPlan.geojson.geometry.coordinates
      if (flightPlan.type === 'map') {
        let camera = getCamera(this.props.droneList, flightPlan.camera)
        let flightPath = pathify(flightPlan, camera)
        let start = flightPath.path.geometry.coordinates[0]
        start = L.GeoJSON.coordsToLatLng(start)
        let end =
          flightPath.path.geometry.coordinates[
            flightPath.path.geometry.coordinates.length - 1
          ]
        end = L.GeoJSON.coordsToLatLng(end)
        return (
          <div key={hash(flightPlan.id || flightPlan.uuid)}>
            <Polygon
              className="view-shape"
              positions={L.GeoJSON.coordsToLatLngs(coordinates[0])}
              color="#00C5D5"
              zIndexOffset={100}
            />
            {/* TODO: replace the temporary start and end markers */}
            <Marker
              className="start-end-marker"
              position={start}
              icon={startMarker}
              zIndexOffset={0}
              interactive={false}
            />
            <Marker
              className="start-end-marker"
              position={end}
              icon={endMarker}
              zIndexOffset={0}
            />
          </div>
        )
      }
      if (flightPlan.type === 'panorama') {
        return (
          <Marker
            className="view-shape"
            key={hash(flightPlan.id || flightPlan.uuid)}
            position={L.GeoJSON.coordsToLatLng(coordinates)}
            icon={panoMarker}
          />
        )
      }
      if (flightPlan.type === 'orbit' && flightPlan.parameters.radii) {
        let options = {
          steps: flightPlan.parameters.photoCount || 8,
          angle: flightPlan.parameters.angle || 0,
          properties: {},
        }
        let ellipseGEOJSON = ellipse(
          flightPlan.geojson,
          flightPlan.parameters.radii.x,
          flightPlan.parameters.radii.y,
          options
        )
        let camera = getCamera(this.props.droneList, flightPlan.camera)
        let flightPath = pathify(flightPlan, camera)
        let start = flightPath.path.geometry.coordinates[0]
        start = L.GeoJSON.coordsToLatLng(start)
        return (
          <div key={hash(flightPlan.id || flightPlan.uuid)}>
            <Marker
              className="view-shape"
              position={L.GeoJSON.coordsToLatLng(coordinates)}
              icon={markerImage}
            />
            <Polygon
              className="view-shape"
              positions={L.GeoJSON.coordsToLatLngs(
                ellipseGEOJSON.geometry.coordinates[0]
              )}
              color="#00C5D5"
            />
            <Marker
              className="start-end-marker"
              position={start}
              icon={startMarker}
              zIndexOffset={0}
              interactive={false}
            />
          </div>
        )
      } else {
        return ''
      }
    })
  }

  renderMapCoverage() {
    let coverage = getMapCoverage(
      this.props.metadatas,
      this.props.flightPlan.parameters,
      this.props.droneCamera
    )
    if (coverage.geometry.type === 'Polygon') {
      return (
        <Polygon
          className="view-shape"
          positions={L.GeoJSON.coordsToLatLngs(
            coverage.geometry.coordinates[0]
          )}
        />
      )
    } else if (coverage.geometry.type === 'MultiPolygon') {
      let polygons = coverage.geometry.coordinates
      return polygons.map((polygon, key) => {
        let coordinate = polygon[0]
        return (
          <Polygon
            key={key}
            className="view-shape"
            positions={L.GeoJSON.coordsToLatLngs(coordinate)}
          />
        )
      })
    }
  }

  renderOrbitUnqualifiedMarkers() {
    let unqualifiedCoordinates = []
    let flightPath = pathify(this.props.flightPlan, this.props.droneCamera)
    let qualityInfo = orbitQualify(
      this.props.metadatas,
      flightPath,
      this.props.flightPlan.geojson,
      this.props.flightPlan.parameters
    )
    // parse all unqualified coordinates
    for (let error of qualityInfo.errors) {
      unqualifiedCoordinates.push({
        lat: error.data.expectedCoordinate.latitude,
        lng: error.data.expectedCoordinate.longitude,
      })
    }
    return unqualifiedCoordinates.map((coord, key) => {
      let isWithinRange = false
      for (let markers of this.props.previewUploadedMarkers) {
        let coordinates = [coord.lat, coord.lng]
        if (
          markers.coordinates &&
          rhumbDistance(coordinates, markers.coordinates, { units: 'meters' }) <
            2
        ) {
          isWithinRange = true
        }
      }
      return isWithinRange ? null : (
        <Marker
          key={key}
          className="view-shape"
          position={coord}
          icon={unqualifiedMarkerImage}
        />
      )
    })
  }

  /**
   * Product annotations used when viewing a Product within a Project's Review Tab
   */
  renderAnnotations = (annotations) => {
    return annotations.map((annotation) => {
      if (annotation.visible) {
        if (
          annotation.type === 'map-volume' ||
          annotation.type === 'map-area'
        ) {
          return (
            <Polygon
              className="annotation"
              positions={L.GeoJSON.coordsToLatLngs(
                annotation.geojson.coordinates[0]
              )}
              color={`#${annotation.color}`}
              key={hash(annotation.id)}
              data-id={annotation.id}
              onClick={() => {
                this.props.editAnnotation(annotation.id)
              }}
            >
              <AnnotationTooltip annotation={annotation} />
            </Polygon>
          )
        } else if (annotation.type === 'map-distance') {
          return (
            <Polyline
              className="annotation"
              positions={L.GeoJSON.coordsToLatLngs(
                annotation.geojson.coordinates
              )}
              color={`#${annotation.color}`}
              key={hash(annotation.id)}
              data-id={annotation.id}
              onClick={() => {
                this.props.editAnnotation(annotation.id)
              }}
            >
              <AnnotationTooltip annotation={annotation} />
            </Polyline>
          )
        } else if (annotation.type === 'map-location') {
          const markerHtmlStyles = `
                  background-color: ${`#${annotation.color}`};
                  width: 1rem;
                  height: 1rem;
                  display: block;
                  left: -.5rem;
                  top: -.5rem;
                  position: relative;
                  border-radius: 3rem 3rem 0;
                  transform: rotate(45deg);`

          const icon = L.divIcon({
            className: 'annotation-marker-icon',
            iconAnchor: [0, 8],
            labelAnchor: [-6, 0],
            popupAnchor: [0, -36],
            html: `<span style="${markerHtmlStyles}" />`,
          })

          return (
            <Marker
              className="annotation-marker"
              position={[
                annotation.geojson.coordinates[1],
                annotation.geojson.coordinates[0],
              ]}
              icon={icon}
              key={hash(annotation.id)}
              data-id={annotation.id}
              onClick={() => {
                this.props.editAnnotation(annotation.id)
              }}
            >
              <AnnotationTooltip annotation={annotation} />
            </Marker>
          )
        } else {
          return <div></div>
        }
      } else {
        return <div></div>
      }
    })
  }

  /**
   * Profile Graph related marker to show elevation along an annotation shape as it relates
   * to the profile graph plotting from the GIS service
   */
  renderProfileCoordinateMarker(coordinate) {
    if (coordinate) {
      return (
        <Marker key="profile" position={L.GeoJSON.coordsToLatLng(coordinate)} />
      )
    }
  }

  renderPdfTileLayer = (projectId, name, layerNumber) => {
    let url = `${PDF_PATH}/${projectId}/${name}/{z}/{x}/{y}.png`
    let maxNativeZoom = this.props.mapMaxNativeZoomLevel

    return (
      <TileLayer
        key={layerNumber}
        url={url}
        zIndex={150 - layerNumber}
        maxZoom={maxNativeZoom}
        maxNativeZoom={17} // Value hardcoded on the lambda function
        tms={true}
        opacity={0.75}
      />
    )
  }

  renderMapTileLayer = (product, layerType, layerNumber) => {
    let url
    let maxNativeZoom = this.props.mapMaxNativeZoomLevel
    let type
    switch (layerType) {
      case 'dsm':
        type = 'dem/'
        break
      case 'ndvi':
        type = 'ndvi/'
        break
      default:
        type = ''
    }
    if (product.bucket !== null) {
      url = `${product.bucket.base_url}/${product.id}/${type}{z}/{x}/{y}.png`
    } else {
      url = `${API_ROOT}/public/products/${product.id}/${type}/{z}/{x}/{y}.png`
    }

    if (product.data && product.data.tile_layers && layerType === 'dsm') {
      maxNativeZoom = product.data.tile_layers.elevation.max_zoom_level
    } else if (
      product.data &&
      product.data.tile_layers &&
      product.data.tile_layers.orthomosaic
    ) {
      maxNativeZoom = product.data.tile_layers.orthomosaic.max_zoom_level
    }

    return (
      <TileLayer
        url={url}
        zIndex={201 - layerNumber}
        maxZoom={26}
        maxNativeZoom={maxNativeZoom}
      />
    )
  }

  /**
   * Toast-style button that revises search bounds when you click on it.
   * It appears when you've moved the map
   */
  renderRevisedSearchBounds = () => {
    let reviseSearchElement
    if (
      this.props.reviseSearchBounds &&
      this.props.projectsMapCenter.length &&
      !this.props.openSingleProject
    ) {
      reviseSearchElement = (
        <div className="revise-search button visible" onClick={this.searchArea}>
          Search this area
        </div>
      )
    } else {
      reviseSearchElement = <div className="revise-search button"></div>
    }
    return reviseSearchElement
  }

  onEachFeatureGeoJSON = (feature, layer) => {
    if (feature.properties) {
      // group the non-geospatial info first
      let tooltipText = '<div class="customTooltip">'
      Object.keys(feature.properties).forEach((key, index) => {
        if (feature.properties.hasOwnProperty(key)) {
          if (
            !['projection', 'northing/latitude', 'easting/longitude'].includes(
              key
            )
          ) {
            if (key === 'name') {
              tooltipText =
                tooltipText +
                `<span><strong><div class="title">${feature.properties[key]}</div></strong></span><br>`
            }
            if (key === 'spexigon') {
              if (typeof layer.setStyle === 'function') {
                // display visually display spexigon already on these products as darker
                if (this.props.products.length > 0) {
                  this.props.products.forEach((product) => {
                    if (
                      product.parameters &&
                      product.parameters.spexigon_flight_id &&
                      feature.properties[key].id ===
                        product.parameters.spexigon_flight_id
                    ) {
                      // visualizing failures
                      if (
                        product.status !== 'done' &&
                        product.job &&
                        product.job.status === 'failed'
                      ) {
                        layer.setStyle({ fillOpacity: 0.9, fillColor: 'red' })
                      } else {
                        layer.setStyle({ fillOpacity: 0.8 })
                      }
                    }
                  })
                }
              }
              tooltipText =
                tooltipText +
                `<span><img src="https://uploads-ssl.webflow.com/63c6eef857b3bbfdb2dbcab8/63c6eef857b3bbdfa5dbcb1f_logo%20bbg.svg" width="125">
                <strong><div>${feature.properties[key].zone_hash}</div></strong>
                Flight ID: ${feature.properties[key].id}
                <br/> Created ${feature.properties[key].created_at}
                <br/> Flight Type: ${feature.properties[key].name}
                </span><br/>Click hexagon to generate product<br>`
            }
            if (key === 'description') {
              tooltipText = tooltipText + `${feature.properties[key]}<br>`
            }
            if (key === 'Gauge_in') {
              tooltipText =
                tooltipText + `Gauge_in: ${feature.properties[key]}<br>`
            }
            if (key === 'MCODist') {
              tooltipText =
                tooltipText + `MCODist: ${feature.properties[key]}<br>`
            }
            // if stroke color property is set for a geometry, respect that original geojson colour above our overall layer colour
            if (key === 'stroke') {
              if (typeof layer.setStyle === 'function') {
                layer.setStyle({ color: feature.properties[key] })
              }
            }
          }
        }
      })

      let geoTooltipText = '<div class="geo-data">'
      Object.keys(feature.properties).forEach((key, index) => {
        if (feature.properties.hasOwnProperty(key)) {
          if (
            ['projection', 'northing/latitude', 'easting/longitude'].includes(
              key
            )
          ) {
            geoTooltipText =
              geoTooltipText +
              `<span>${key} : ${feature.properties[key]} </span><br>`
          }
        }
      })

      geoTooltipText = geoTooltipText + '</div>'

      tooltipText = tooltipText + geoTooltipText + '</div>'

      layer.bindTooltip(tooltipText, {
        direction: 'top',
        offset: [0, -5],
        opacity: 1,
      })
    }
  }

  initProductMapLayers = (layers) => {
    layers.map((layer) => {
      if (
        layer.visible &&
        gjv.valid(layer.geojson) &&
        !this.vectorLayers[layer.name]
      ) {
        this.vectorLayers[layer.name] = L.vectorGrid.slicer(layer.geojson, {
          zIndex: 210,
          rendererFactory: L.svg.tile,
          vectorTileLayerStyles: {
            sliced: function (properties, zoom) {
              const color = properties.stroke || `#${layer.color}`
              return {
                fillColor: color,
                opacity: 0.7,
                color: color,
                weight: 2,
                radius: zoom < 22 ? 1 : 8,
              }
            },
          },
          maxZoom: 26,
          interactive: true,
        })
        this.vectorLayers[layer.name].addTo(this.vectorLayerGroup)
      }
      if (!layer.visible && this.vectorLayers[layer.name]) {
        this.vectorLayerGroup.removeLayer(this.vectorLayers[layer.name])
        // this.vectorLayers[layer.name].remove()
        delete this.vectorLayers[layer.name]
      }
      return layer
    })
  }

  // renderProductMapLayers = (layers) => {
  //   return layers.map((layer) => {
  //     if (layer.visible && gjv.valid(layer.geojson) && !this.contours) {
  //       return (
  //         <>
  //           <GeoJSON
  //             key={hash(layer.id + layer.color)}
  //             data={layer.geojson}
  //             color={`#${layer.color}`}
  //             onEachFeature={this.onEachFeatureGeoJSON}
  //             pointToLayer={(feature, latlng) => {
  //               const markerHtmlStyles = `
  //                 background-color: ${`#${layer.color}`};
  //                 width: 6px;
  //                 height: 6px;
  //                 display: block;
  //                 left: -3px;
  //                 top: -3px;
  //                 position: relative;
  //                 border-radius: 1rem 1rem 0;
  //                 transform: rotate(45deg);`

  //               const icon = L.divIcon({
  //                 className: 'annotation-marker-icon',
  //                 iconAnchor: [0, 3],

  //                 html: `<span style="${markerHtmlStyles}" />`,
  //               })
  //               return L.marker(latlng, { icon })
  //             }}
  //           ></GeoJSON>
  //         </>
  //       )
  //     } else {
  //       return ''
  //     }
  //   })
  // }

  // Render users in the platform for admins, if they have given us their address
  renderPlatformUsers(user) {
    if (user.profile && user.profile.geolocation) {
      let marker = userMarker
      return (
        <Marker
          key={user.id}
          position={[
            user.profile.geolocation.coordinates[1],
            user.profile.geolocation.coordinates[0],
          ]}
          icon={marker}
          zIndexOffset={200}
        >
          <Tooltip
            direction={'top'}
            offset={L.point(0, -60)}
            className="search-address-tooltip"
          >
            <span>
              {user.name}
              <br />
              {user.email}
            </span>
          </Tooltip>

          <Popup
            direction={'top'}
            offset={L.point(0, -60)}
            className="leaflet-popup-user"
          >
            <UserCard
              user={user}
              userId={user.id}
              isLargeFormat={false}
              isAdmin={false}
            />
            <div className="user-card-actions">
              {!this.props.isEdittingProject &&
                !this.props.isAddingProject &&
                !this.props.openSingleProject && (
                <Link
                  to="/add-project"
                  className="add-button"
                  onClick={() => {
                    this.props.setIsAddingProject(true)
                    this.props.setProjectForm({
                      ...this.props.projectForm,
                      operator_id: user.id,
                    })
                    this.props.leafletMapElement.closePopup()
                  }}
                >
                  <button>Assign to new project</button>
                </Link>
              )}

              {(this.props.isEdittingProject ||
                this.props.isAddingProject ||
                this.props.openSingleProject) && (
                <button
                  onClick={() => {
                    if (
                      this.props.isAddingProject ||
                      this.props.isEdittingProject
                    ) {
                      this.props.setProjectForm({
                        ...this.props.projectForm,
                        operator_id: user.id,
                      })
                      this.props.leafletMapElement.closePopup()
                    } else if (
                      this.props.openSingleProject &&
                      !this.props.isEdittingProject &&
                      !this.props.isAddingProject
                    ) {
                      this.props.updateProjectData({
                        ...this.props.project,
                        operator_id: user.id,
                      })
                      this.props.leafletMapElement.closePopup()
                    }
                  }}
                >
                  Assign as operator
                </button>
              )}

              <button
                onClick={() => {
                  if (navigator.clipboard) {
                    navigator.clipboard.writeText(user.email)
                  }
                }}
              >
                Copy email to clipboard
              </button>
            </div>
          </Popup>
        </Marker>
      )
    }
  }

  toggleUserControls = (e) => {
    if (e) {
      e.preventDefault()
    }
    let toggle = this.state.userControls ? false : true
    this.setState({ userControls: toggle })
  }

  createSpexigonProduct = async (spexigonData) => {
    this.props.setRequestInProgress(true, 'GLOBAL')
    const request = await Spexigon.get(spexigonData.zone_hash)
    if (request.success) {
      console.log(request.data)
      Products.create({
        name: `${spexigonData.zone_hash} - Flight #${spexigonData.id}`,
        spexigon: spexigonData,
        type: 'spexigon-multipano',
        project_id: this.props.project.id,
        status: 'new',
        parameters: {
          spexigon_zone_hash: spexigonData.zone_hash,
          spexigon_flight_id: spexigonData.id,
          spexigon_bucket: spexigonData.files_uri
            .replace(/^https?:\/\//, '')
            .replace(/\..*/, ''),
          model_rotation: {
            rotation_method: 'vector_sum',
          },
        },
      }).then((response) => {
        this.props.setRequestInProgress(false, 'GLOBAL')
        if (response && response.success) {
          const product_id = response.data.id
          Products.createJob({ product_id }).then(() => {
            this.props.getProducts(this.props.project.id)
          })
        }
      })
    }
  }

  render() {
    // console.log('render ', this.props.projectsMapCenter)
    let flightPlans =
      (this.props.isAddingProject || this.props.isEdittingProject) &&
      (!this.props.isEdittingFlightPlan || !this.props.isAddingFlightPlan)
        ? this.props.projectForm.flightPlans
        : this.props.flightPlans

    const isAdminOrOrgAdmin =
      this.props.user &&
      (this.props.user.isAdmin || this.props.user.isOrgOwnerOrAdmin)

    return (
      <section id="dashboard-content">
        <NotificationMessage {...this.props} />
        {this.renderRevisedSearchBounds()}
        {this.state.searchBar && <SearchBar {...this.props} />}
        <Map
          center={this.props.projectsMapCenter}
          // center={[49.250304, -122.902938]}
          maxZoom={26}
          maxNativeZoom={this.props.mapMaxNativeZoomLevel}
          zoom={this.props.mapZoomLevel}
          zoomControl={false}
          animate={true}
          onEdited={this.handleEdit}
          ref={(mapRef) => {
            this._mapRef = mapRef
          }}
          whenReady={this.mapRef}
          onViewportChanged={this.onViewportChanged}
          editable={true}
          boxZoom={false}
        >
          {this.props.mapLayers.basemap && (
            <LayersControl position="bottomright">
              <BaseLayer name="Roads">
                <GoogleLayer maxZoom={22} googlekey={key} maptype={road} />
              </BaseLayer>
              <BaseLayer checked name="Terrain">
                <GoogleLayer
                  maxZoom={22}
                  googlekey={key}
                  maptype={terrain}
                  libraries={['geometry', 'places', 'drawing']}
                />
              </BaseLayer>
              <BaseLayer name="Satellite">
                <GoogleLayer maxZoom={22} googlekey={key} maptype={satellite} />
              </BaseLayer>
              <BaseLayer name="OpenStreetMaps">
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  zIndex={100}
                  maxZoom={26}
                  maxNativeZoom={21}
                />
              </BaseLayer>
            </LayersControl>
          )}

          {this.props.mapLayers.pdfs &&
            this.props.mapLayers.pdfs.map((pdfLayer, index) => {
              return this.renderPdfTileLayer(
                pdfLayer.project_id,
                pdfLayer.name,
                index
              )
            })}

          <CustomControls className="leaflet-bar leaflet-right">
            <a href="/search" onClick={this.searchBarToggle}>
              &nbsp;<i className="fa fa-search" aria-hidden="true"></i>
            </a>
            {/* <a href="/refresh" onClick={this.fetchProjects}>&nbsp;<i className="fa fa-refresh" aria-hidden="true"></i></a> */}
            <a href="/location" onClick={this.getLocationHandler}>
              &nbsp;<i className="fa fa-location-arrow" aria-hidden="true"></i>
            </a>
          </CustomControls>

          {this.props.match.path !== '/add-project' &&
            !this.props.openSingleProject &&
            this.props.projects.map((project) => {
              let coordinates = project.centroid.coordinates
              return (
                <Marker
                  key={hash(project.id)}
                  position={[coordinates[1], coordinates[0]]}
                  icon={projectMarker}
                  onClick={() => {
                    this.props.fetchProject(project.id)
                  }}
                  projectId={project.id}
                  zIndexOffset={100}
                >
                  <Tooltip
                    direction={'top'}
                    offset={L.point(0, -60)}
                    className="search-address-tooltip"
                  >
                    <span>{project.name}</span>
                  </Tooltip>
                </Marker>
              )
            })}

          {this.props.match.path !== '/add-project' &&
            !this.props.openSingleProject &&
            this.props.userGeoLocation.length > 1 && (
            <Marker
              position={this.props.userGeoLocation}
              icon={geoLocationIcon}
              zIndexOffset={0}
            />
          )}

          {this.props.users.length > 0 &&
            this.props.users.map((user) => this.renderPlatformUsers(user))}

          {flightPlans &&
            (this.props.openSingleProject || this.props.isAddingProject) &&
            !this.props.isEdittingFlightPlan &&
            !this.props.match.path.includes('/project/:id/review/:pid') &&
            this.renderFlightPlans(flightPlans)}

          {this.props.metadatas.length > 0 &&
            this.props.flightPlan &&
            this.props.flightPlan.type === 'map' &&
            this.renderMapCoverage()}
          {this.props.metadatas.length > 0 &&
            this.props.flightPlan &&
            this.props.flightPlan.type === 'orbit' &&
            this.renderOrbitUnqualifiedMarkers()}

          {this.renderProfileCoordinateMarker(this.props.profileCoordinate)}

          {(this.props.match.path === '/project/:id/review/:pid' ||
            this.props.match.path ===
              '/project/:id/review/:pid/add-annotation' ||
            this.props.match.path === '/project/:id/review/:pid/:aid/edit') &&
            this.props.openSingleProject &&
            (this.props.product.type === 'map' ||
              this.props.product.type === 'map+3d') &&
            this.props.mapLayers.vegetation &&
            this.renderMapTileLayer(this.props.product, 'ndvi', 1)}

          {(this.props.match.path === '/project/:id/review/:pid' ||
            this.props.match.path ===
              '/project/:id/review/:pid/add-annotation' ||
            this.props.match.path === '/project/:id/review/:pid/:aid/edit') &&
            this.props.openSingleProject &&
            (this.props.product.type === 'map' ||
              this.props.product.type === 'map+3d') &&
            this.props.mapLayers.dsm &&
            this.renderMapTileLayer(this.props.product, 'dsm', 1)}

          {(this.props.match.path === '/project/:id/review/:pid' ||
            this.props.match.path ===
              '/project/:id/review/:pid/add-annotation' ||
            this.props.match.path === '/project/:id/review/:pid/:aid/edit' ||
            this.props.match.path === '/project/:id/review/:pid/export') &&
            this.props.openSingleProject &&
            (this.props.product.type === 'map' ||
              this.props.product.type === 'map+3d') &&
            this.props.mapLayers.orthomosaic &&
            this.renderMapTileLayer(this.props.product, 2)}

          {(this.props.match.path === '/project/:id/review/:pid' ||
            this.props.match.path ===
              '/project/:id/review/:pid/add-annotation' ||
            this.props.match.path === '/project/:id/review/:pid/:aid/edit' ||
            this.props.match.path === '/project/:id/review/:pid/export') &&
            this.props.openSingleProject &&
            (this.props.product.type === 'map' ||
              this.props.product.type === 'map+3d') &&
            this.props.annotations &&
            this.renderAnnotations(this.props.annotations)}

          {this.props.searchMarker && this.props.searchMarker.coordinates && (
            <Marker position={this.props.searchMarker.coordinates}>
              <Tooltip
                permanent
                direction={'right'}
                className="search-address-tooltip"
              >
                <span>{this.props.searchMarker.addressText}</span>
              </Tooltip>
              perm
            </Marker>
          )}

          <ZoomControl position="bottomright" />
          {this.props.match.path === '/project/:id/process' &&
            this.props.spexigonData.length > 0 &&
            this.props.spexigonData.map((spexigon, index) => {
              const hexGeoJSON = latLngToH3GeoJson(
                cellToLatLng(spexigon.zone_hash, true)
              )
              hexGeoJSON.properties.spexigon = spexigon
              if (hexGeoJSON) {
                return (
                  <GeoJSON
                    key={spexigon.zone_hash + index}
                    data={hexGeoJSON}
                    color="#4200FF"
                    onEachFeature={this.onEachFeatureGeoJSON}
                    onClick={(e) => {
                      const spexigonData = e.layer.feature.properties.spexigon
                      // colour the layer so people understand they already created on here.
                      e.layer.setStyle({ fillOpacity: 0.8 })
                      this.createSpexigonProduct(spexigonData)
                    }}
                  />
                )
              } else {
                return <></>
              }
            })}
        </Map>

        {isAdminOrOrgAdmin && (
          <section
            className={
              this.state.userControls
                ? 'user-controls open'
                : 'user-controls closed'
            }
          ></section>
        )}

        {this.state.isSpexigonSelectModalOpen && (
          <Modal
            closeHandler={() =>
              this.setState({ isSpexigonSelectModalOpen: false })
            }
          >
            Helo
          </Modal>
        )}
      </section>
    )
  }
}

export default MainMap
